import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let muiTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#062A93",
    },
    secondary: {
      main: "#67A6F8",
    },
    background: {
      default: "#f8f8f8",
    },
    error: {
      main: "#ef6262",
    },
    warning: {
      main: "#e48538",
    },
    info: {
      main: "#45a5da",
    },
    success: {
      main: "#5fd265",
    },
  },
});

muiTheme = responsiveFontSizes(muiTheme);

export default muiTheme;
