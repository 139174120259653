// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const roqSlice = createSlice({
  name: "roq",
  initialState: {
    done: false,
    riskProfile: { qa: [], finalScore: 0 },
  },
  reducers: {
    setRiskProfileQA: (state, action) => {
      state.riskProfile.qa.push(action.payload);
    },
    setRiskProfileFinalScore: (state, action) => {
      state.riskProfile.finalScore = action.payload;
    },
    setRiskProfileDone: (state, action) => {
      state.done = true;
    },
  },
});

export default roqSlice.reducer;

export const {
  setRiskProfileQA,
  setRiskProfileFinalScore,
  setRiskProfileDone,
} = roqSlice.actions;
