import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
    window.location.reload(true);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        redirect_uri: window.location.origin,
        onRedirectCallback: onRedirectCallback,
        scope:
          "openid profile email read:accounts create:accounts update:accounts delete:accounts read:portfolios create:portfolios update:portfolios delete:portfolios",
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
