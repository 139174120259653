// ** React Imports
import { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

// Auth
import Auth0ProviderWithHistory from "./auth/Auth0ProviderWithHistory";

// ** Intl
import { IntlProviderWrapper } from "./utility/context/Internationalization";
import { MeProviderWrapper } from "./utility/context/MeContext";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";

// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/store";

// ** Toast & ThemeColors Context
import { ToastContainer } from "react-toastify";
import { ThemeContext } from "./utility/context/ThemeColors";

// ** Ripple Button
import "./@core/components/ripple-button";

// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// ** Service Worker
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider } from "@mui/material";
import muiTheme from "./configs/muiTheme";

// Sentry
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://3e87afa1c7c6b98739e968337c10271d@o4506310366658560.ingest.sentry.io/4506310368034816",
  environment: process.env.NODE_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/api\/asesoresinteligentes\.com\//,
      ],
    }),
    new Sentry.Replay({
      maskAllText: true,
      maskAllInputs: true,
      blockAllMedia: true,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <Router>
        <Auth0ProviderWithHistory>
          <MeProviderWrapper>
            <ThemeProvider theme={muiTheme}>
              <ThemeContext>
                <IntlProviderWrapper>
                  <LazyApp />
                  <ToastContainer newestOnTop />
                </IntlProviderWrapper>
              </ThemeContext>
            </ThemeProvider>
          </MeProviderWrapper>
        </Auth0ProviderWithHistory>
      </Router>
    </Suspense>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
