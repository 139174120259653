// ** Reducers Imports
import layout from "./layout";
import onboarding from "./onboarding";
import roq from "./roq";
import sa from "./sa";

const rootReducer = {
  layout,
  onboarding,
  roq,
  sa,
};

export default rootReducer;
