import { useEffect, useState, createContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

// ** Create Context
const Context = createContext();

const MeProviderWrapper = ({ children }) => {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();

  // ** State
  const [me, setMe] = useState(null);
  const [meLoading, setMeLoading] = useState(true);

  const getMe = async () => {
    const token = await getAccessTokenSilently();

    await axios
      .get(`${process.env.REACT_APP_API_URL}/Me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const data = res.data.result;
        setMe(data);
      })
      .catch(function () {})
      .finally(() => {
        setMeLoading(false);
      });
  };
  //** ComponentDidMount
  useEffect(() => {
    if (isAuthenticated) {
      getMe();
    }
    // eslint-disable-next-line
  }, [isLoading]);

  return (
    <Context.Provider value={{ me, meLoading, getMe }}>
      {children}
    </Context.Provider>
  );
};

export { MeProviderWrapper, Context as MeContext };
