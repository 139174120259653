// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState: {
    contactInformation: {},
    employmentInformation: {},
    financialBackground: {},
  },
  reducers: {
    setContactInformation: (state, action) => {
      state.contactInformation = action.payload;
    },
    setEmploymentInformation: (state, action) => {
      state.employmentInformation = action.payload;
    },
    setFinancialBackground: (state, action) => {
      state.financialBackground = action.payload;
    },
  },
});

export default onboardingSlice.reducer;

export const {
  setContactInformation,
  setEmploymentInformation,
  setFinancialBackground,
} = onboardingSlice.actions;
