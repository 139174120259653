// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const saSlice = createSlice({
  name: "sa",
  initialState: {
    advisorData: {},
  },
  reducers: {
    setAdvisorData: (state, action) => {
      state.advisorData = action.payload;
    },
  },
});

export default saSlice.reducer;

export const { setAdvisorData } = saSlice.actions;
